<template>
  <div class="container my-4 text-center selection:bg-green-500">
    <h1 class="my-10 text-4xl font-semibold">About Us</h1>
    <div class="space-y-10 text-sm text-center sm:text-lg selection:bg-yellow-300 selection:text-black">
      <div>
        Apar’s Tech Team হচ্ছে Apar’s Student Group (ASG) এর একটি টেকনিক্যালি
        ডেভেলপড টিম। যারা ASG তে যেকোনো প্রকার টেক সাপোর্ট দিয়ে থাকে। পাশাপাশি
        আমাদের প্রায় ১ লক্ষ পরিবারের এই গ্রুপটিতে নানান সময় নানান রকমের প্রজেক্ট
        লঞ্চ হয়, যার প্রতিটিতেই এই টেক টিমের মেম্বাররাই অংশগ্রহণ করে। অর্থাৎ
        তোমার স্কিল ডেভেলপ করার, তোমার স্কিলের মাধ্যমে নতুন কিছু করার এবং তোমার
        স্কিলকে ASG পরিবারের সাথে ব্যবহার করার একটি সুবর্ণ সুযোগ হতে পারে এটি।
      </div>
      <div>
        Apar’s Tech Team তৈরি করা হয় মূলত ASG এর Technological Research &amp;
        Development এর জন্যে। এটি কোনো পেইড জব না তবে আমাদের অপার ভাইয়া
        প্রতিনিয়ত নিজে এর দেখাশোনা করেন এবং ভালো কোনো আইডিয়া বা প্রজেক্ট দেখাতে
        পারলে যথেষ্ট পরিমাণ ফান্ডিং পর্যন্ত এনে দেন। সুতরাং এই গ্রুপটি হতে পারে
        সবার প্রতিভা দেখানোর একটি মাধ্যম, পাশাপাশি আমাদের গ্রুপের সকল Tech
        Enthusiast দের একটি মিলন মেলা।
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>